/* General Styles */
body {
    background-color: #080c10; /* Black background */
    color: white; /* White text for contrast */
    font-family: Arial, sans-serif; /* Revert to a general font */
}

/* Container Styles */
.container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    background: #fff; /* White background for contrast */
    border: 1px solid #ccc; /* Add border back */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header Styles */
header {
    background-color: #080c10; /* Black background */
    padding: 20px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

header h1 {
    color: white; /* White text */
    font-size: 36px;
}

/* Button Styles */
button {
    background: rgba(34, 151, 184, 0.8);
    border: none;
    color: white;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    padding: 10px 20px;
    border-radius: 5px;
    transition: all 0.3s ease;
	margin:2px 0;
    box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
    font-family: 'Luckiest Guy', cursive; /* Apply Luckiest Guy font only to buttons */
}

button:hover {
    background: rgba(34, 151, 184, 0.9);
    transform: translateY(-2px);
    box-shadow: 0px 12px 20px rgba(0, 0, 0, 0.2);
}

button:active {
    transform: translateY(2px);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

/* Card Styles */
.card {
    background: rgba(0, 0, 0, 0.7); /* Black with semi-transparency */
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 10px;
    color: white;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column; /* Ensure the card content is stacked vertically */
    border: 1px solid #ccc; /* Add border back */
}

/* Form Styles */
form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 600px;
    background: rgb(0, 0, 0);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #ccc; /* Add border back */
}

form input,
form textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Footer Styles */
footer {
    background-color: #080c10; /* Black background */
    padding: 20px;
    text-align: center;
    box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.2);
}

footer p {
    color: white; /* White text */
    font-size: 14px;
}


/* src/screens/CharacterEditScreen.css */
.image-selection {
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  .image-row {
	display: flex;
	justify-content: space-around;
	align-items: flex-end;
	width: 100%;
  }
  
  .image-row .offset-middle {
	margin-top: 20px; /* Adjust as needed */
  }
  
  .image-row .offset-center {
	margin-top: 40px; /* Adjust as needed */
  }



/* Add this to your CSS file */
.edit-screen-container {
  position: relative;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
}

.character-portrait {
  text-align: center;
  margin-bottom: 20px;
}

.portrait-image {
  max-width: 100%;
  max-height: 300px; /* Adjust this value as needed */
  object-fit: contain;
  border-radius: 20px;
}

.character-info {
  margin-top: 10px;
}

.character-info p {
  margin: 5px 0;
}


.add-entity-button {
	display: block;
	margin: 20px auto;
	background-color: transparent;
	color: green;
	font-size: 44px;
	border: none;
	cursor: pointer;
	text-align: center;
  }
  
  .add-entity-button:hover {
	color: darkgreen;
  }