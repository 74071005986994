.link {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown {
  display: inline-block;
}

.dropdown-button {
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content-show {
  display: block;
}